import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextFieldNormalColorsDisabled } from "./styled";
import { CONSTANTS, FILTER_INPUT_SIZE_MEDIUM } from "constants/general";

export const InfiniteScrollAutocomplete = ({
  hasMore,
  options,
  handleLoadMore,
  handleSearchChange,
  value,
  handleChange,
  label = "Options",
  searchTerm,
  error,
  helperText,
  handleClear,
  hasNotNone,
  notClearable,
  disabled,
  isFilter,
  size = FILTER_INPUT_SIZE_MEDIUM,
}) => {
  const [state, setState] = useState(null);
  let timeout;

  const handleSearch = (e) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => handleSearchChange(e), 700);
  };

  const autoCompleteOptions = options.length
    ? hasNotNone
      ? options.map((el) => ({ value: el.id, name: el.title }))
      : [
          { value: null, name: "None" },
          ...options.map((el) => ({ value: el.id, name: el.title })),
        ]
    : [];

  useEffect(() => {
    const selectedOption = options.find((el) => el.id === value);
    const selectedValue = {
      value,
      name: selectedOption?.title,
    };
    setState(selectedOption ? selectedValue : null);
  }, [value, options]);

  useEffect(() => {
    if (searchTerm !== null) {
      const selectedValue = {
        value: "",
        name: searchTerm,
      };
      setState(selectedValue);
    }
  }, [searchTerm]);

  return (
    <Autocomplete
      fullWidth
      value={state}
      options={autoCompleteOptions}
      disabled={disabled}
      className={isFilter && "custom-infinity-select"}
      size={size}
      onInput={(e) => handleSearch(e)}
      onChange={(event, newValue, reason) => {
        if (reason === CONSTANTS.CLEAR) {
          setState(null);
        }
        if (newValue === null) {
          handleClear(); // Clear selection
        } else {
          handleChange(event, newValue);
        }
      }}
      disablePortal
      isOptionEqualToValue={(option, value) => option.value === value.value}
      freeSolo={false} // Prevent free input
      renderInput={(params) => (
        <TextFieldNormalColorsDisabled
          {...params}
          label={label}
          isselected={(!!state).toString()}
          error={error}
          size={size}
          disabled={disabled}
          helperText={helperText}
          variant="outlined"
          InputLabelProps={{
            shrink: !!state || params.inputProps.value ? true : undefined,
            className: isFilter && "custom-infinity-label",
          }}
          InputProps={{
            ...params.InputProps,
            className: isFilter && "custom-infinity-input",
          }}
        />
      )}
      getOptionLabel={(option) => option.name}
      disableClearable={notClearable}
      clearable="true"
      ListboxProps={{
        onScroll: (event) => {
          const listbox = event.target;
          if (
            listbox.scrollTop + listbox.clientHeight === listbox.scrollHeight &&
            hasMore
          ) {
            handleLoadMore();
          }
        },
      }}
    />
  );
};
