import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, Tooltip } from "@mui/material";

import { CONSTANTS } from "constants/general";

import {
  BackIcon,
  CreateIcon,
  EditFillIcon,
  FilterIcon,
} from "components/icons";
import loaderIcon from "assets/images/actions/loader.svg";
import { BOOK } from "constants/books";

const ComponentHeader = ({
  edit,
  editPath,
  create,
  handleCreateEdit,
  back,
  noMargin,
  isDepartment,
  disableCreateButton,
  backPath,
  filter,
  handleFilter,
  updatePrices,
  handleUpdatePrice,
}) => {
  const updatePriceLoading = useSelector(
    (state) => state.book.updatePriceLoading
  );

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(backPath || -1);
  };

  return (
    <div
      className={`pages_header ${noMargin && "no_margin"} ${
        isDepartment && "add_position"
      }`}
    >
      <div>
        {back && (
          <Button
            variant="contained"
            className="back_button"
            onClick={handleBack}
          >
            <BackIcon width="16" height="16" className="back_icon" />
            {CONSTANTS.BACK_BUTTON}
          </Button>
        )}
      </div>
      <div className={disableCreateButton && "width_400"}>
        {filter && (
          <Tooltip title={CONSTANTS.FILTER} arrow>
            <Button
              variant="outlined"
              className="action_button create_edit_action btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
              onClick={handleFilter}
            >
              <FilterIcon width="25" height="25" className="font-size-lg" />
            </Button>
          </Tooltip>
        )}
        {edit && (
          <Link to={editPath}>
            <Tooltip title={CONSTANTS.EDIT} arrow>
              <Button
                variant="contained"
                className="action_button create_edit_action btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
              >
                <EditFillIcon width="32" height="32" className="font-size-lg" />
              </Button>
            </Tooltip>
          </Link>
        )}
        {updatePrices && (
          <Button
            variant="contained"
            className="update_price_btn btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={handleUpdatePrice}
          >
            {updatePriceLoading ? (
              <img
                src={loaderIcon}
                alt="Loader"
                className="price_loader_icon"
              />
            ) : (
              <span>{BOOK.update_price}</span>
            )}
          </Button>
        )}
        {create && (
          <Tooltip title={CONSTANTS.CREATE} arrow>
            <Button
              variant="outlined"
              className="action_button create_edit_action btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
              onClick={handleCreateEdit}
              disabled={disableCreateButton}
            >
              <CreateIcon width="32" height="32" className="font-size-lg" />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ComponentHeader;
