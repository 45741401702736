import { FilterChip, FilterStack, FilterButton } from "./styled";
import { TABLE_BUTTON_OBJ } from "./constants";

export const AppliedFilters = ({
  appliedFilters,
  handleRemoveFilter,
  handleClearFilters,
}) => {
  const hasAnyValidFilter = appliedFilters.some((filter) => filter);
  return (
    <div className="active_filters_container">
      <FilterStack direction="row" spacing={1}>
        {appliedFilters.map((filter, idx) => {
          return (
            filter &&
            filter.value !== null &&
            filter.value?.map((elem, index) => (
              <FilterChip
                key={`${idx}${index}`}
                label={`${filter.name}: ${filter.valueName[index]}`}
                onDelete={() => handleRemoveFilter(filter.queryKey, index)}
                variant="outlined"
              />
            ))
          );
        })}
      </FilterStack>
      {appliedFilters?.length > 0 && hasAnyValidFilter && (
        <FilterButton variant="outlined" onClick={handleClearFilters}>
          {TABLE_BUTTON_OBJ.clearAll}
        </FilterButton>
      )}
    </div>
  );
};
