import { styled } from "@mui/system";

import {
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Switch,
} from "@mui/material";
import { MUI_BREAKPOINTS } from "constants/general";

export const CustomInputLabel = styled(InputLabel)(({ iserror }) => ({
  color: iserror ? "#d32f2f" : "rgba(0, 0, 0, 0.6)",
}));

export const CustomFormHelperText = styled(FormHelperText)(() => ({
  color: "#d32f2f",
}));

export const StyledMenuItem = styled(MenuItem)(() => ({}));

export const StyledSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 19,
  padding: 0,
  marginRight: 10,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "white",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 15,
  },
  "& .MuiSwitch-track": {
    borderRadius: 9,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  flexDirection: "column-reverse",
  gap: "5px",

  ".MuiTypography-root": {
    minWidth: "56px",
  },

  [theme.breakpoints.down(MUI_BREAKPOINTS.mobile)]: {
    flexDirection: "row-reverse",
  },
}));
