import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalWindow from "components/ModalWindow";
import { TABLE_MIN_PAGE, USER_PER_PAGE, CONSTANTS } from "constants/general";
import { getColumns } from "./columns";
import { PrimaryTable } from "components/PrimaryTable";
import { EmptyRowsOverlay } from "./components/emptyRowsOverlay";
import { deleteUser, getUsers, blockUser } from "actions/usersActions";

export const Users = () => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openBlockPopup, setOpenBlockPopup] = useState(false);
  const [goToPage, setGoToPage] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [blockId, setBlockId] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);

  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.data);
  const totalCount = useSelector((state) => state.users.totalCount);

  const totalPageNumber = Math.ceil(totalCount / USER_PER_PAGE);

  const openDeleteModal = (row) => {
    setDeleteId(row?.id);
    setOpen(true);
  };

  const openBlockModal = (row) => {
    setBlockId(row?.id);
    setIsBlocked(row?.is_blocked);
    setOpenBlockPopup(true);
  };

  const columns = getColumns(openDeleteModal, openBlockModal);

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const goToSpecificPage = () => {
    const newPage = Number(goToPage);
    if (newPage >= TABLE_MIN_PAGE && newPage <= totalPageNumber) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    dispatch(getUsers({ params: { page, per_page: USER_PER_PAGE } }));
  }, [page]);

  return (
    <div className="table_page_body_container">
      {open && (
        <ModalWindow
          open={open}
          setOpen={setOpen}
          id={deleteId}
          confirmRequest={deleteUser}
          currentPage={page}
          message={CONSTANTS.DELETE_BOOK}
          confirmBtnText={CONSTANTS.DELETE}
          params={{ page }}
        />
      )}
      {openBlockPopup && (
        <ModalWindow
          open={openBlockPopup}
          setOpen={setOpenBlockPopup}
          id={blockId}
          title={isBlocked ? CONSTANTS.UNBLOCK_TITLE : CONSTANTS.BLOCK_TITLE}
          confirmRequest={blockUser}
          currentPage={page}
          message={isBlocked ? CONSTANTS.UNBLOCK_USER : CONSTANTS.BLOCK_USER}
          confirmBtnText={isBlocked ? CONSTANTS.UNBLOCK : CONSTANTS.BLOCK}
          params={{ page }}
        />
      )}
      <PrimaryTable
        rows={users?.length ? users : []}
        columns={columns}
        handlePageChange={handlePageChange}
        handleGoToPageChange={handleGoToPageChange}
        totalPageNumber={totalPageNumber}
        page={page}
        goToPage={goToPage}
        goToSpecificPage={goToSpecificPage}
        hasPagination={totalCount > USER_PER_PAGE}
        EmptyRowsOverlay={EmptyRowsOverlay}
      />
    </div>
  );
};
