import { useState } from "react";

export default function FilterHelpers(initialFilters = []) {
  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  const handleApplyFilter = (filters) => {
    setAppliedFilters(filters);
  };

  const handleRemoveFilter = (key, index) => {
    const findFilterByKey = appliedFilters.find(
      (filter) => filter?.queryKey === key
    );
    if (findFilterByKey?.value?.length > 1) {
      const appliedFiltersAfterRemove = appliedFilters.map((filter) => {
        if (filter?.queryKey === key) {
          return {
            ...findFilterByKey,
            value: findFilterByKey?.value?.filter((_, idx) => index !== idx),
            valueName: findFilterByKey?.valueName?.filter(
              (_, idx) => index !== idx
            ),
          };
        }
        return filter;
      });
      setAppliedFilters(appliedFiltersAfterRemove);
    } else {
      const appliedFiltersAfterRemove = appliedFilters.filter(
        (filter) => filter?.queryKey !== key
      );
      setAppliedFilters(appliedFiltersAfterRemove);
    }
  };

  const handleClearFilters = () => {
    setAppliedFilters([]);
  };

  const appliedFiltersToParams = (filters) => {
    let params = {};
    for (let i = 0; i < filters.length; i++) {
      if (filters[i]) {
        params[filters[i].queryKey] = filters[i].value;
      }
    }
    return params;
  };

  return {
    appliedFiltersToParams,
    handleApplyFilter,
    handleRemoveFilter,
    handleClearFilters,
    appliedFilters,
  };
}
