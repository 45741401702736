import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ComponentHeader } from "components";
import ModalWindow from "components/ModalWindow";
import { CONSTANTS } from "constants/general";

import { deleteGenre, getGenres } from "actions/bookActions";

import { getColumns } from "./columns";
import { PrimaryTable } from "components/PrimaryTable";
import { EmptyRowsOverlay } from "./components/emptyRowsOverlay";
import { CreateEditGenre } from "./createEditGenre";

export const Genres = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [openCreateEditModal, setOpenCreateEditModal] = useState(false);

  const genres = useSelector((state) => state.book.genres);

  const openDeleteModal = (row) => {
    setDeleteId(row?.id);
    setOpen(true);
  };

  const handleEdit = (id) => {
    setEditId(id);
    setOpenCreateEditModal(true);
  };

  const columns = getColumns(openDeleteModal, handleEdit);

  const handleRowClick = (param) => console.log(param);

  const handleCreate = () => {
    setEditId(null);
    setOpenCreateEditModal(true);
  };

  const closePopup = () => {
    setOpenCreateEditModal(false);
  };

  useEffect(() => {
    dispatch(getGenres());
  }, []);

  return (
    <div className="table_page_body_container">
      <ComponentHeader create handleCreateEdit={handleCreate} />
      {open && (
        <ModalWindow
          open={open}
          setOpen={setOpen}
          id={deleteId}
          confirmRequest={deleteGenre}
          message={CONSTANTS.GENRE_DELETE}
          confirmBtnText={CONSTANTS.DELETE}
        />
      )}
      <div className="create_edit">
        {openCreateEditModal && (
          <div className="popup">
            <CreateEditGenre id={editId} close={closePopup} />
          </div>
        )}
      </div>
      <PrimaryTable
        rows={genres.length ? genres : []}
        columns={columns}
        handleCreate={handleCreate}
        handleRowClick={handleRowClick}
        EmptyRowsOverlay={EmptyRowsOverlay}
      />
    </div>
  );
};
