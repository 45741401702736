import React from "react";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "constants/general";
import saveIcon from "assets/images/actions/save.svg";
import loaderIcon from "assets/images/actions/loader.svg";

const StyledButton = styled(MuiButton)(({ theme, colortype }) => {
  let mainColor, darkColor, fontColor;

  switch (colortype) {
    case "primary":
      mainColor = "#1C479C";
      darkColor = "#1C479C";
      fontColor = "white";
      break;
    case "secondary":
      mainColor = "#1C479C";
      darkColor = "#1C479C";
      fontColor = "white";
      break;
    case "tertiary":
      mainColor = "#71C6FF";
      darkColor = "#5B98B5";
      fontColor = "white";
      break;
    case "success":
      mainColor = "green";
      darkColor = "darkgreen";
      fontColor = "white";
      break;
    case "warning":
      mainColor = "#FFEB3B";
      darkColor = "#FBC02D";
      fontColor = "black";
      break;
    case "danger":
      mainColor = "red";
      darkColor = "darkred";
      fontColor = "white";
      break;
    default:
      mainColor = "#1C479C";
      darkColor = "#75187F";
      fontColor = "white";
  }

  return {
    gap: "8px",
    backgroundColor: `${mainColor} !important`,
    color: `${fontColor} !important`,
    "&:hover": {
      backgroundColor: `${darkColor} !important`,
    },
  };
});

export const MainButton = ({ type = "primary", children, ...rest }) => {
  const isSuccessOrErr = type === "success" || type === "danger";
  return (
    <StyledButton variant="contained" colortype={type} {...rest}>
      {isSuccessOrErr && <FontAwesomeIcon icon={faBan} />}
      {children}
    </StyledButton>
  );
};

export const SaveButton = ({ loading = false }) => {
  return (
    <button type="submit" className="form_button" disabled={loading}>
      {loading ? (
        <img src={loaderIcon} alt="Loader" className="loader_icon" />
      ) : (
        <>
          {CONSTANTS.SAVE}
          <img src={saveIcon} alt="Save" className="save_icon" />
        </>
      )}
    </button>
  );
};
