import styled from "@emotion/styled";
import { Button, Chip } from "@mui/material";
import { Stack } from "@mui/system";
import { COLOR } from "constants/styleConstants";

export const FilterStack = styled(Stack)({
  marginBottom: 6,
  display: "flex",
  flexWrap: "wrap",
  columnGap: "6px",
  rowGap: "3px",
});

export const FilterChip = styled(Chip)({
  background: `${COLOR.primary} !important`,
  textTransform: "capitalize",
  marginLeft: "0 !important",
});

export const EmptyOverlayBlock = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const FilterButton = styled(Button)({
  height: "fit-content",
  minWidth: "135px !important",
});
