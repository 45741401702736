import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const booksSchema = [
  {
    id: "cover",
    name: "cover",
    label: CONSTANTS.COVER_IMAGE,
    type: "fileImage",
    placeholder: CONSTANTS.COVER_IMAGE,
    backend_field: "cover",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "pdf",
    name: "pdf",
    label: CONSTANTS.PDF,
    type: "filePdf",
    placeholder: CONSTANTS.PDF,
    backend_field: "pdf",
    validation: Yup.string()
      .nullable()
      .when(["audio"], {
        is: (audio) => !audio,
        then: Yup.string().required(CONSTANTS.REQUIRED),
      }),
  },
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "author",
    name: "author",
    label: CONSTANTS.AUTHOR,
    type: "text",
    placeholder: CONSTANTS.AUTHOR,
    backend_field: "author",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "genre_ids",
    name: "genre_ids",
    label: CONSTANTS.GENRES,
    type: "multipleSelect",
    placeholder: CONSTANTS.GENRES,
    backend_field: "genre_ids",
    validation: Yup.array()
      .of(Yup.string())
      .required(CONSTANTS.REQUIRED)
      .min(1, CONSTANTS.REQUIRED),
  },
  {
    id: "page_count",
    name: "page_count",
    label: CONSTANTS.PAGES_COUNT,
    type: "text",
    placeholder: CONSTANTS.PAGES_COUNT,
    backend_field: "page_count",
    validation: Yup.string()
      .nullable()
      .when(["pdf"], {
        is: (pdf) => pdf,
        then: Yup.string().nullable().required(CONSTANTS.REQUIRED),
      }),
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "label",
    name: "label",
    label: CONSTANTS.LABEL,
    type: "select",
    placeholder: CONSTANTS.LABEL,
    backend_field: "label",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    input_name: "Label",
    price_name: "label_price",
    price_label: "Price",
  },
  {
    id: "google_play_iap_id",
    name: "google_play_iap_id",
    label: CONSTANTS.GOOGLE_PRICE,
    type: "select_price",
    placeholder: CONSTANTS.GOOGLE_PRICE,
    backend_field: "google_play_iap_id",
    validation: Yup.string()
      .nullable()
      .when([CONSTANTS.LABEL_KEY, CONSTANTS.APPLY_KEY], {
        is: (label, app_store_iap_id) =>
          label === CONSTANTS.PAID_VALUE && !app_store_iap_id,
        then: Yup.string().nullable().required(CONSTANTS.REQUIRED_MESSAGE),
      }),
    input_name: CONSTANTS.GOOGLE_PRICE,
  },
  {
    id: "app_store_iap_id",
    name: "app_store_iap_id",
    label: CONSTANTS.APPLE_PRICE,
    type: "select_price",
    placeholder: CONSTANTS.APPLE_PRICE,
    backend_field: "app_store_iap_id",
    validation: Yup.string()
      .nullable()
      .when([CONSTANTS.LABEL_KEY, CONSTANTS.GOOGLE_KEY], {
        is: (label, google_play_iap_id) =>
          label === CONSTANTS.PAID_VALUE && !google_play_iap_id,
        then: Yup.string().nullable().required(CONSTANTS.REQUIRED_MESSAGE),
      }),
    input_name: CONSTANTS.APPLE_PRICE,
  },
  {
    id: "audio",
    name: "audio",
    label: CONSTANTS.AUDIO,
    type: "fileAudio",
    placeholder: CONSTANTS.AUDIO,
    backend_field: "audio",
    validation: Yup.string()
      .nullable()
      .when(["pdf"], {
        is: (pdf) => !pdf,
        then: Yup.string().required(CONSTANTS.REQUIRED),
      }),
  },
  {
    id: "google_play_price",
    name: "google_play_price",
    label: CONSTANTS.LABEL,
    type: "select_price",
    placeholder: CONSTANTS.LABEL,
    backend_field: "google_play_price",
    validation: Yup.string().nullable(),
    input_name: "google_play_price",
    price_name: "google_play_price",
    price_label: "google_play_price",
    not_show: true,
  },
  {
    id: "app_store_price",
    name: "app_store_price",
    label: CONSTANTS.LABEL,
    type: "select_price",
    placeholder: CONSTANTS.LABEL,
    backend_field: "app_store_price",
    validation: Yup.string().nullable(),
    input_name: "app_store_price",
    price_name: "app_store_price",
    price_label: "app_store_price",
    not_show: true,
  },
];
