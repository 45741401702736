import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Button, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";

import { formErrorHelper, formikValidation } from "helpers/formikValidation";
import { CONSTANTS } from "constants/general";
import { genreSchema } from "./genreSchema";
import {
  FORM_ITEM_NAMES_OBJ,
  FORM_ITEM_TYPES_OBJ,
  TEXTFIELD_VARIANTS_OBJ,
} from "service/constants";
import { SaveButton } from "components/Button";
import { createGenre, getGenreById, updateGenre } from "actions/bookActions";
import { TextInput } from "components";

export const CreateEditGenre = ({ close, id }) => {
  const [editContent, setEditContent] = useState({
    title: "",
  });
  const [errMessage, setErrMessage] = useState("");

  const modalRef = useRef();
  const dispatch = useDispatch();

  const [initialValues, validation] = formikValidation(genreSchema);

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      id
        ? dispatch(
            updateGenre({
              id,
              data: values,
              setErrMessage,
              close,
            })
          )
        : dispatch(createGenre({ data: values, setErrMessage, close }));
    },
  });

  const handleFieldChange = (name, value) => {
    if (value !== " ") formik.setFieldValue(name, value);
  };

  useEffect(() => {
    if (editContent) {
      genreSchema.forEach((elem) => {
        formik.setFieldValue(elem.name, editContent[elem.name]);
      });
    }
  }, [editContent]);

  useEffect(() => {
    if (id) {
      dispatch(getGenreById({ id })).then((res) =>
        setEditContent(res?.payload?.data ?? {})
      );
    }
  }, [id]);

  return (
    <div ref={modalRef} className="form_container">
      <form onSubmit={formik.handleSubmit} className="create_edit_form">
        <div className="cancel_button">
          <Button onClick={close}>
            <Close />
          </Button>
        </div>
        <div className="field_Container">
          <TextInput
            formik={formik}
            name={FORM_ITEM_NAMES_OBJ.title}
            label={CONSTANTS.TITLE}
            handleChange={handleFieldChange}
            errMessage={errMessage}
            isGenre
          />
        </div>
        <SaveButton />
      </form>
    </div>
  );
};
