import Notify from "helpers/Notify";
import instance from "./baseRequest";

export const authApi = {
  login: async ({ email, password, rem }) => {
    const res = await instance.post("/v1/admin/auth/signin", {
      email,
      password,
    });

    rem
      ? localStorage.setItem("access_token", res.data.data.token)
      : sessionStorage.setItem("access_token", res.data.data.token);

    return res;
  },
  logout: async () => await instance.post("/v1/admin/auth/logout"),

  changePassword: async (body) =>
    await instance.post("/v1/admin/auth/change-password", body),
};
