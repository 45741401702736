import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { downloadAudioPdf, getBookById } from "actions/bookActions";
import { ComponentHeader } from "components";
import { RowElement } from "./components";
import { BOOK } from "constants/books";
import { getCreateDate } from "helpers/dateHelper";

import pdfImage from "assets/images/pdf.png";

const imageBase = process.env.REACT_APP_API_IMAGE_URL;

export const BookDetails = () => {
  const data = useSelector((state) => state.book.bookById);

  const { id } = useParams();
  const dispatch = useDispatch();

  const editPath = `/books/edit/${id}`;

  const downloadPdf = () => {
    const splitFile = data.pdf.split(".");
    const download_type = splitFile[1];

    dispatch(downloadAudioPdf({ type: BOOK.pdf_type, id, download_type }));
  };

  useEffect(() => {
    dispatch(getBookById({ id }));
  }, []);

  return (
    <div className="details_section">
      <ComponentHeader back edit editPath={editPath} />
      <div className="details_content">
        <div className="details_component">
          <div className="image_section">
            <p className="row_title">{BOOK.cover_image}</p>
            <img
              src={`${imageBase}${data?.cover}`}
              alt="Cover"
              className="cover_image"
            />
          </div>
          <div className="details_row">
            <RowElement title={BOOK.title} description={data?.title} />
            <RowElement title={BOOK.author} description={data?.author} />
          </div>
          <div className="details_row">
            <RowElement title={BOOK.genres} content={data?.genres} />
            <RowElement
              title={BOOK.page_count}
              description={data?.page_count}
            />
          </div>
          <div className="details_row">
            <RowElement
              title={BOOK.description}
              description={data?.description}
            />
            <RowElement
              title={BOOK.label}
              description={data?.label}
              customClass="capitalize"
            />
          </div>
          <div className="details_row">
            <RowElement
              title={BOOK.created_at}
              description={getCreateDate(data?.createdAt)}
            />
            <RowElement
              title={BOOK.download_count}
              description={data.download_count}
            />
          </div>
          <div className="details_row">
            <div className="row_element">
              <p className="row_title">{BOOK.pdf}:</p>
              {!!data?.pdf ? (
                <button className="pdf_download" onClick={downloadPdf}>
                  <img src={pdfImage} alt="PDF" className="pdf_image" />
                </button>
              ) : (
                <p className="row_description capitalize">
                  {BOOK.no_such_file}
                </p>
              )}
            </div>
            <div className="row_element">
              <p className="row_title">{BOOK.audio}:</p>
              {!!data?.audio ? (
                <audio controls className="audio">
                  <source src={`${imageBase}${data?.audio}`}></source>
                </audio>
              ) : (
                <p className="row_description capitalize">
                  {BOOK.no_such_file}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
