import React from "react";
import { MainButton } from "components/Button";
import styled from "@emotion/styled";
import { CONSTANTS } from "constants/general";

const EmptyOverlayContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "16px",
  alignItems: "center",
  height: "100%",
});

export const EmptyRowsOverlay = ({ handleCreate }) => {
  return (
    <EmptyOverlayContainer>
      <div>{CONSTANTS.NO_USER}</div>
    </EmptyOverlayContainer>
  );
};
