import Button from "@mui/material/Button";
import Notify from "helpers/Notify";
import { NOTIFY } from "constants/notifyType";
import { getError } from "helpers/getError";
import { BlockedIcon, DeleteIcon, UnBlockedIcon } from "components/icons";
import { CONSTANTS } from "constants/general";
import { Tooltip } from "@mui/material";
import { StatusLabel } from "components";
import { USER_STATUS } from "service/constants";

export const getColumns = (deleteUser, blockUser) => [
  {
    field: "full_name",
    headerName: "Full Name",
    flex: 2,
    width: 100,
    sortable: false,
  },
  { field: "email", headerName: "Email", flex: 2, width: 100, sortable: false },
  {
    field: "downloaded_books_count",
    headerName: "Downloaded books",
    flex: 2,
    width: 100,
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    flex: 1,
    width: 100,
    sortable: false,
    renderCell: (params) => {
      if (!params.value) return "-";
      const date = new Date(params.value);
      return date.toLocaleDateString();
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 180,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const handleDelete = async (event) => {
        event.stopPropagation();
        try {
          await deleteUser({ id: row._id });
        } catch (err) {
          Notify(NOTIFY.type.error, getError(err));
        }
      };

      const handleBlockUnblock = async (event) => {
        event.stopPropagation();
        blockUser({ id: row._id, is_blocked: row.is_blocked });
      };

      return (
        <div className="user_actions_container flex_center">
          {row.is_blocked ? (
            <StatusLabel
              className="blocked_label hover_label"
              onClick={handleBlockUnblock}
              name={USER_STATUS.blocked}
            >
              <BlockedIcon width="20" height="20" />
            </StatusLabel>
          ) : (
            <StatusLabel
              className="completed_label hover_label"
              onClick={handleBlockUnblock}
              name={USER_STATUS.active}
            >
              <UnBlockedIcon width="20" height="20" />
            </StatusLabel>
          )}
          <Tooltip title={CONSTANTS.REMOVE} arrow>
            <Button
              className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              <DeleteIcon className="font-size-lg edit_icon" />
            </Button>
          </Tooltip>
        </div>
      );
    },
  },
];
