import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  TableHead,
  Button,
  Tooltip,
} from "@mui/material";
import {
  StyledTableCell,
  TableCellWithoutBorder,
  StyledBox,
  StyledTable,
  SubtitleRow,
  ElementRow,
} from "./styledComponents";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { CONSTANTS, TABLE } from "constants/general";
import { PrimaryPagination } from "components/PrimaryPagination";
import { DeleteIcon, EditIcon } from "components/icons";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, TABLE.zero_value);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - TABLE.changed_page_count);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + TABLE.changed_page_count);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(
        TABLE.zero_value,
        Math.ceil(count / rowsPerPage) - TABLE.changed_page_count
      )
    );
  };

  return (
    <StyledBox>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === TABLE.zero_value}
        aria-label="first page"
      >
        {theme.direction === TABLE.rtl ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === TABLE.zero_value}
        aria-label="previous page"
      >
        {theme.direction === TABLE.rtl ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={
          page >= Math.ceil(count / rowsPerPage) - TABLE.changed_page_count
        }
        aria-label="next page"
      >
        {theme.direction === TABLE.rtl ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={
          page >= Math.ceil(count / rowsPerPage) - TABLE.changed_page_count
        }
        aria-label="last page"
      >
        {theme.direction === TABLE.rtl ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </StyledBox>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const TableComponent = ({
  page,
  setPage,
  data,
  title,
  totalPageNumber,
  subtitle,
  setEditContent,
  setDeleteId,
  setOpen,
  handleOpenChild = () => {},
  handleGoToPageChange,
  goToSpecificPage,
  goToPage,
  hasPagination,
  setIsEdit,
  setIsOpenCreateEdit,
}) => {
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEdit = (e, data) => {
    e.stopPropagation();
    dispatch(setEditContent(data));
    setIsOpenCreateEdit(true);
    setIsEdit(true);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteId(id);
    setOpen(true);
  };

  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCellWithoutBorder component="th" scope="row">
              <p className="table_title">{title}</p>
            </TableCellWithoutBorder>
          </TableRow>
          <SubtitleRow>
            <StyledTableCell component="th" scope="row" colSpan={2}>
              <p className="table_head_title">{subtitle}</p>
            </StyledTableCell>
          </SubtitleRow>
        </TableHead>
        <TableBody>
          {data?.length ? (
            data.map((elem) => (
              <ElementRow
                key={elem?.id}
                hover={true}
                onClick={() => handleOpenChild(elem)}
              >
                <StyledTableCell component="th" scope="row">
                  {elem?.title}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="right">
                  <div className="actions_container">
                    <Tooltip title={CONSTANTS.EDIT} arrow>
                      <Button
                        variant="contained"
                        className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        onClick={(e) => handleEdit(e, elem)}
                      >
                        <EditIcon className="font-size-lg edit_icon" />
                      </Button>
                    </Tooltip>
                    <Tooltip title={CONSTANTS.REMOVE} arrow>
                      <Button
                        variant="contained"
                        className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        onClick={(e) => handleDelete(e, elem?.id)}
                      >
                        <DeleteIcon className="font-size-lg edit_icon" />
                      </Button>
                    </Tooltip>
                  </div>
                </StyledTableCell>
              </ElementRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2} component="th" scope="row"></TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>
      {hasPagination && (
        <PrimaryPagination
          totalPageNumber={totalPageNumber}
          page={page}
          handlePageChange={handleChangePage}
          goToPage={goToPage}
          handleGoToPageChange={handleGoToPageChange}
          goToSpecificPage={goToSpecificPage}
        />
      )}
    </TableContainer>
  );
};
