import instance, { formDataInstance } from "./baseRequest";

export const bookApi = {
  getBooks: async (params) => await instance.get("/v1/book", { params }),
  getBookById: async (id) => await instance.get(`/v1/book/${id}`),
  deleteBook: async (id) => await instance.delete(`/v1/book/${id}`),
  createBook: async (body) => await formDataInstance.post("/v1/book", body),
  updateBook: async (body, id) =>
    await formDataInstance.put(`/v1/book/${id}`, body),
  getGenreById: async (id) => await instance.get(`/v1/genre/${id}`),
  getGenres: async () => await instance.get("/v1/genre"),
  createGenre: async (body) => await instance.post("/v1/genre", body),
  updateGenre: async (body, id) => await instance.put(`/v1/genre/${id}`, body),
  deleteGenre: async (id) => await instance.delete(`/v1/genre/${id}`),
  downloadAudioPdf: async (type, id) =>
    instance.get(`/v1/book/download/${type}/${id}`),
  updatePrices: async () => await instance.post("/v1/admin/update-prices"),
};
