import { TextField } from "@mui/material";
import { TEXTFIELD_VARIANTS_OBJ } from "service/constants";
import { formErrorHelper } from "helpers/formikValidation";

export const TextInput = ({
  formik,
  name,
  label,
  type = "text",
  multiline,
  size = "medium",
  handleChange,
  errMessage = "",
  isGenre = false,
}) => {
  return (
    <TextField
      fullWidth
      id={name}
      size={size}
      name={name}
      multiline={multiline}
      minRows={4}
      maxRows={10}
      label={label}
      variant={TEXTFIELD_VARIANTS_OBJ.outlined}
      type={type}
      value={formik.values[name] ?? ""}
      onChange={(e) => handleChange(name, e.target.value)}
      error={
        formErrorHelper({
          formik,
          elementName: name,
          isBoolean: true,
        }) ||
        (isGenre && !!errMessage)
      }
      helperText={
        formErrorHelper({
          formik,
          elementName: name,
        }) || errMessage
      }
    />
  );
};
