import React from "react";
import { Pagination } from "@mui/material";
import {
  InnerBox,
  StyledBox,
  StyledPaginationItem,
  StyledTextField,
} from "./styledItems";

export const PrimaryPagination = ({
  totalPageNumber,
  page,
  handlePageChange,
  goToPage,
  handleGoToPageChange,
  goToSpecificPage,
}) => (
  <StyledBox>
    <Pagination
      count={totalPageNumber}
      page={page}
      onChange={handlePageChange}
      renderItem={(item) => (
        <StyledPaginationItem {...item} selected={item.page === Number(page)} />
      )}
    />
    <InnerBox>
      <label>Go to</label>
      <StyledTextField
        value={goToPage}
        onChange={handleGoToPageChange}
        variant="outlined"
        type="number"
        inputProps={{ min: 1 }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            goToSpecificPage();
          }
        }}
      />
    </InnerBox>
  </StyledBox>
);
