import { useEffect } from "react";

const useOutsideClick = (ref, handler, id) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      // Check if the target element is the pop-up opening component
      const popupOpeningComponent = document.getElementById(id);
      if (
        popupOpeningComponent &&
        popupOpeningComponent.contains(event.target)
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler, id]);
};

export default useOutsideClick;
