import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PdfUploader } from "components";
import { BOOK } from "constants/books";
import { CustomFormHelperText } from "./styled";

const AntTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },

  ".Mui-selected": {
    borderTop: "1px solid",
    boxSizing: "border-box",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
    "&.MuiButtonBase-root": {
      backgroundColor: "white",
      marginRight: "0",
    },
  })
);

export const UploadTabs = ({ formik }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ bgcolor: "#fff" }}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label={BOOK.upload_pdf} />
          <AntTab label={BOOK.upload_audio} />
        </AntTabs>
      </Box>
      <Box sx={{ bgcolor: "#fff" }}>
        {value ? (
          <>
            <PdfUploader
              type={BOOK.audio_type}
              value={formik.values.audio}
              formik={formik}
              isError={formik?.touched?.audio && formik?.errors?.audio}
            />
            {formik?.touched?.audio && formik?.errors?.audio && (
              <CustomFormHelperText>
                {formik.errors?.audio}
              </CustomFormHelperText>
            )}
          </>
        ) : (
          <>
            <PdfUploader
              type={BOOK.pdf_type}
              value={formik.values.pdf}
              formik={formik}
              isError={formik?.touched?.pdf && formik?.errors?.pdf}
            />
            {formik?.touched?.pdf && formik?.errors?.pdf && (
              <CustomFormHelperText>{formik.errors?.pdf}</CustomFormHelperText>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
