import { BOOK } from "constants/books";

export const getCreateDate = (date) => {
  const newDate = new Date(date);

  const day = newDate.getDate();
  const month = newDate.getMonth() + BOOK.fix_month;
  const year = newDate.getFullYear();

  const paddedDay = day < BOOK.check_to_add_zero ? `0${day}` : day;
  const paddedMonth = month < BOOK.check_to_add_zero ? `0${month}` : month;

  return `${paddedDay}/${paddedMonth}/${year}`;
};
