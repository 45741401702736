import * as Yup from "yup";
import { CONSTANTS, PASSWORD_MESSAGES } from "constants/general";

export const changePasswordSchema = [
  {
    id: "old_password",
    name: "old_password",
    label: CONSTANTS.OLD_PASSWORD,
    type: "password",
    placeholder: CONSTANTS.OLD_PASSWORD,
    backend_field: "old_password",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "new_password",
    name: "new_password",
    label: CONSTANTS.PASSWORD,
    type: "password",
    placeholder: CONSTANTS.PASSWORD,
    backend_field: "new_password",
    validation: Yup.string()
      .notOneOf(
        [Yup.ref("old_password"), null],
        PASSWORD_MESSAGES.old_new_password_validation
      )
      .min(6, PASSWORD_MESSAGES.minMaxCharacter)
      .max(20, PASSWORD_MESSAGES.minMaxCharacter)
      .required(CONSTANTS.REQUIRED),
  },
  {
    id: "new_password_confirm",
    name: "new_password_confirm",
    label: CONSTANTS.CONFIRM_PASSWORD,
    type: "password",
    placeholder: CONSTANTS.CONFIRM_PASSWORD,
    backend_field: "new_password_confirm",
    validation: Yup.string()
      .oneOf([Yup.ref("new_password"), null], PASSWORD_MESSAGES.password_match)
      .min(6, PASSWORD_MESSAGES.minMaxCharacter)
      .max(20, PASSWORD_MESSAGES.minMaxCharacter)
      .required(CONSTANTS.REQUIRED),
  },
];
