import { Routes, Route, Navigate } from "react-router";
import { useSelector } from "react-redux";

import LeftSidebar from "LeftSidebar";

import {
  ChangePassword,
  Books,
  Login,
  Genres,
  BookDetails,
  CreateEditBook,
  Users,
} from "pages";

const PageRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {!isAuth ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <LeftSidebar>
          <Routes>
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/books" element={<Books />} />
            <Route path="/books/create" element={<CreateEditBook />} />
            <Route path="/books/edit/:id" element={<CreateEditBook />} />
            <Route path="/genres" element={<Genres />} />
            <Route path="/book-details/:id" element={<BookDetails />} />
            <Route path="/users" element={<Users />} />
            <Route path="/*" element={<Navigate to="/books" replace />} />
          </Routes>
        </LeftSidebar>
      )}
    </>
  );
};

export default PageRoutes;
