import { TextField as MUITextField } from "@mui/material";
import { styled } from "@mui/system";

export const TextFieldNormalColorsDisabled = styled(MUITextField)`
  & .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .MuiInputBase-root.Mui-disabled {
    color: ${(props) =>
      props.disabled
        ? "rgba(0, 0, 0, 0.38)"
        : props.isselected === "true"
        ? "rgba(0, 0, 0, 0.87)"
        : "rgba(0, 0, 0, 0.54)"};
    -webkit-text-fill-color: ${(props) =>
      props.disabled
        ? "rgba(0, 0, 0, 0.38)"
        : props.isselected === "true"
        ? "rgba(0, 0, 0, 0.87)"
        : "rgba(0, 0, 0, 0.54)"};

    .MuiOutlinedInput-input {
      -webkit-text-fill-color: ${(props) =>
        props.disabled
          ? "rgba(0, 0, 0, 0.38)"
          : props.isselected === "true"
          ? "rgba(0, 0, 0, 0.87)"
          : "rgba(0, 0, 0, 0.54)"};
      color: ${(props) =>
        props.disabled
          ? "rgba(0, 0, 0, 0.38)"
          : props.isselected === "true"
          ? "rgba(0, 0, 0, 0.87)"
          : "rgba(0, 0, 0, 0.54)"};
    }
  }

  label.Mui-disabled {
    color: ${(props) =>
      props.disabled
        ? "rgba(0, 0, 0, 0.38)"
        : props.isselected === "true"
        ? "rgba(0, 0, 0, 0.87)"
        : "rgba(0, 0, 0, 0.54)"};
  }

  .MuiOutlinedInput-notchedOutline.Mui-disabled {
    border-color: rgba(0, 0, 0, 0.23); // Standard border color
  }
`;
