import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { login } from "features/auth";

//MUI
import {
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import {
  MailOutlineTwoTone,
  LockTwoTone,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { LOGIN_PAGE, CONSTANTS } from "constants/general";

//Styles
import "react-toastify/dist/ReactToastify.css";

//Images
import logo from "assets/images/logo.svg";
import loginImage from "assets/images/login.svg";

export const Login = () => {
  const [rem, setRem] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
    rem: rem,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(CONSTANTS.EMAIL_VALIDATION_MESSAGE),
    password: Yup.string().required(CONSTANTS.INVALID_PASSWORD_MESSAGE),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      navigate("/");
      dispatch(
        login({
          email: values.email,
          password: values.password,
          rem,
        })
      );
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="login_page">
                    <div className="login_page_form">
                      <div className="login_field_container">
                        <div>
                          <div className="logo_filed">
                            <img src={logo} alt="E-Library" />
                          </div>
                          <div className="text_filed">
                            <p className="welcome_text">{LOGIN_PAGE.welcome}</p>
                            <p className="signin_text">
                              {LOGIN_PAGE.sign_in_text}
                            </p>
                          </div>
                          <Box component="form" onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                              <TextField
                                fullWidth
                                variant="outlined"
                                name="email"
                                placeholder={LOGIN_PAGE.email_address}
                                autoComplete="true"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.email && formik.errors.email
                                }
                                helperText={
                                  formik.touched.email &&
                                  formik.errors.email &&
                                  CONSTANTS.EMAIL_VALIDATION_MESSAGE
                                }
                                InputProps={{
                                  required: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MailOutlineTwoTone />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <TextField
                                fullWidth
                                variant="outlined"
                                name="password"
                                placeholder={LOGIN_PAGE.password}
                                type={showPassword ? "text" : "password"}
                                value={formik.values.password}
                                autoComplete="true"
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.password &&
                                  formik.errors.password
                                }
                                helperText={
                                  formik.touched.password &&
                                  formik.errors.password &&
                                  CONSTANTS.INVALID_PASSWORD_MESSAGE
                                }
                                InputProps={{
                                  required: true,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LockTwoTone />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center font-size-md">
                              <FormControlLabel
                                className="login_checkbox"
                                control={
                                  <Checkbox
                                    checked={rem}
                                    onChange={(e) => setRem(!rem)}
                                    value="checked1"
                                    color="primary"
                                  />
                                }
                                label={LOGIN_PAGE.remember_me}
                              />
                            </div>
                            <div className="text-center py-4">
                              <Button
                                type="submit"
                                className="btn-login font-weight-bold w-30 my-2"
                              >
                                {LOGIN_PAGE.sign_in}
                              </Button>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>
                    <div className="login_image_field">
                      <img
                        src={loginImage}
                        alt="Login"
                        className="login_image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
