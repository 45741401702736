import { createTheme } from "@mui/material/styles";

export const dataGridTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          padding: "10px",
          gap: "10px",
          "& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator": {
            visibility: "hidden", // Hide the column separator on hover
          },
          ".MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
          },
          ".MuiDataGrid-sortIcon": {
            opacity: "inherit !important",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "hidden",
          },
          "& .MuiDataGrid-cell": {
            color: "black",
            padding: "0 34px 0 10px"
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#fff",
            color: "#1C479C",
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
            backgroundColor: "white",
            color: "black",
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
          "& .MuiDataGrid-main": {
            maxHeight: "70vh",
          },
          "& .MuiDataGrid-overlayWrapper": {
            height: "100px !important",
            "& .MuiDataGrid-overlayWrapperInner": {
              height: "100% !important",
            },
          },
        },
      },
    },
  },
});
