import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CONSTANTS,
  PAGE,
  SEARCH_KEY,
  defaultReversedSortParams,
} from "constants/general";
import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { bookApi } from "service/bookApi";
import { pricesApi } from "service/pricesApi";

const base_url = process.env.REACT_APP_API_BASE_URL;

export const getBooks = createAsyncThunk(
  "getBooks",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await bookApi.getBooks(params);
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getGenres = createAsyncThunk(
  "getGenres",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await bookApi.getGenres();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getBookById = createAsyncThunk(
  "getBookById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await bookApi.getBookById(id);
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getGenreById = createAsyncThunk(
  "getGenreById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await bookApi.getGenreById(id);
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteBook = createAsyncThunk(
  "deleteBook",
  async ({ id, params }, { rejectWithValue, dispatch }) => {
    try {
      await bookApi.deleteBook(id);
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
      dispatch(getBooks(params));
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createBook = createAsyncThunk(
  "createBook",
  async ({ data, navigate }, { rejectWithValue }) => {
    try {
      await bookApi.createBook(data);
      Notify(NOTIFY.type.success, NOTIFY.message.add);
      navigate(-1);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateBook = createAsyncThunk(
  "updateBook",
  async ({ data, id, navigate }, { rejectWithValue }) => {
    try {
      await bookApi.updateBook(data, id);
      Notify(NOTIFY.type.success, NOTIFY.message.update);
      navigate(-1);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createGenre = createAsyncThunk(
  "createGenre",
  async ({ data, setErrMessage, close }, { rejectWithValue, dispatch }) => {
    try {
      await bookApi.createGenre(data);
      Notify(NOTIFY.type.success, NOTIFY.message.add);
      close();
      dispatch(getGenres());
    } catch (err) {
      if (err.response.data.message === CONSTANTS.already_exists) {
        setErrMessage(CONSTANTS.GENRE_ALREADY_EXIST);
      }
      return rejectWithValue(err.message);
    }
  }
);

export const updateGenre = createAsyncThunk(
  "updateGenre",
  async ({ data, id, setErrMessage, close }, { rejectWithValue, dispatch }) => {
    try {
      await bookApi.updateGenre(data, id);
      Notify(NOTIFY.type.success, NOTIFY.message.update);
      close();
      dispatch(getGenres());
    } catch (err) {
      if (err.response.data.message === CONSTANTS.already_exists) {
        setErrMessage(CONSTANTS.GENRE_ALREADY_EXIST);
      }
      return rejectWithValue(err.message);
    }
  }
);

export const deleteGenre = createAsyncThunk(
  "deleteGenre",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      await bookApi.deleteGenre(id);
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
      dispatch(getGenres());
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getApplePrices = createAsyncThunk(
  "getApplePrices",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await pricesApi.getApplePrices();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getGooglePrices = createAsyncThunk(
  "getGooglePrices",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await pricesApi.getGooglePrices();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const downloadAudioPdf = createAsyncThunk(
  "downloadAudioPdf",
  async ({ type, id, download_type }, { rejectWithValue }) => {
    try {
      const access_token =
        localStorage.getItem("access_token") ||
        sessionStorage.getItem("access_token");

      const response = await fetch(
        `${base_url}/v1/book/download/${type}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to download Book");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `book.${download_type}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updatePrices = createAsyncThunk(
  "updatePrices",
  async (
    { handleClearFilters, setSortTitle, setSortOption, query, navigate },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await bookApi.updatePrices();

      Notify(NOTIFY.type.success, NOTIFY.message.update);
      handleClearFilters();
      setSortTitle(defaultReversedSortParams.sort);
      setSortOption(defaultReversedSortParams.order);
      query.set(PAGE, 1);
      query.set(SEARCH_KEY, "");
      navigate(`?${query.toString()}`);
    } catch (error) {
      rejectWithValue(error);
      Notify(NOTIFY.type.error, NOTIFY.message.wrong);
    }
  }
);
