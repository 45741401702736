import { GenreElement } from "./genreElement";

export const RowElement = ({
  title,
  description,
  content,
  customClass = "",
}) => (
  <div className="row_element">
    <p className="row_title">{title}:</p>
    {content?.length ? (
      <ul className="content_section">
        {content?.map((elem) => (
          <GenreElement key={elem?._id} item={elem} />
        ))}
      </ul>
    ) : (
      <p className={`row_description ${customClass}`}>{description}</p>
    )}
  </div>
);
