import { FormControl, Select } from "@mui/material";
import { useEffect, useState } from "react";
import {
  CustomFormHelperText,
  CustomInputLabel,
  StyledMenuItem,
} from "./styled";
import { CONSTANTS } from "constants/general";
import { INPUT_SIZES } from "constants/general";
import { FORM_ITEM_NAMES_OBJ } from "service/constants";

export const SelectBox = ({
  title,
  data,
  handleSelectChange,
  name,
  formik,
  selectedValue,
  size = INPUT_SIZES.medium,
  nameKey = FORM_ITEM_NAMES_OBJ.name,
  itemKey = CONSTANTS.ID_KEY,
}) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
    const element = data?.find((item) => item[itemKey] === event.target.value);
    handleSelectChange(name, event.target.value, element);
  };

  useEffect(() => {
    if (selectedValue) {
      setValue(`${selectedValue}`);
    }
  }, [selectedValue]);

  return (
    <FormControl fullWidth size={size}>
      <CustomInputLabel
        id="demo-simple-select-label"
        iserror={formik?.touched[name] ? formik?.errors[name] : undefined}
      >
        {title}
      </CustomInputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={title}
        onChange={handleChange}
        error={formik?.touched[name] && Boolean(formik?.errors[name])}
      >
        {data?.map((element) => (
          <StyledMenuItem value={element?.[itemKey]} key={element?.[itemKey]}>
            {element?.[nameKey]}
          </StyledMenuItem>
        ))}
      </Select>
      {formik?.touched[name] && formik?.errors[name] && (
        <CustomFormHelperText>{formik.errors[name]}</CustomFormHelperText>
      )}
    </FormControl>
  );
};
