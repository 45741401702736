import axios from "axios";

import { ACCESS_TOKEN } from "constants/general";
import { RESPONSE_STATUSES } from "./constants";

const base_url = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
  baseURL: base_url,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === RESPONSE_STATUSES.unauthorized) {
      localStorage.removeItem(ACCESS_TOKEN);
      sessionStorage.removeItem(ACCESS_TOKEN);
      if(window.location.pathname !== "/")
        window.location.reload();
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem(ACCESS_TOKEN) ||
      sessionStorage.getItem(ACCESS_TOKEN);
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const formDataInstanceCreator = async (url, data, method = "post") =>
  await instance[method](url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const formDataInstance = {
  post: async (url, data) => formDataInstanceCreator(url, data),
  put: async (url, data) => formDataInstanceCreator(url, data, "put"),
};

export default instance;
