import { createSlice } from "@reduxjs/toolkit";
import {
  getBooks,
  getGenres,
  getBookById,
  getGenreById,
  getGooglePrices,
  getApplePrices,
  createBook,
  updateBook,
  updatePrices,
} from "actions/bookActions";

const initialState = {
  data: [],
  bookById: {},
  genreById: {},
  totalCount: 0,
  appliedFilters: [],
  loading: false,
  genres: [],
  appliedFilters: [],
  applePrices: [],
  googlePrices: [],
  createEditLoading: false,
  updatePriceLoading: false,
};

export const bookSlice = createSlice({
  name: "book",
  initialState,
  reducers: {
    setAppliedFilters: (state, action) => {
      state.appliedFilters = action.payload;
    },
    setBookById: (state, { payload }) => {
      state.bookById = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBooks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBooks.fulfilled, (state, { payload }) => {
        state.data = payload.data.data;
        state.totalCount = payload.data.total_count;
      })
      .addCase(getBooks.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getGenres.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGenres.fulfilled, (state, { payload }) => {
        state.genres = payload?.data;
      })
      .addCase(getGenres.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getBookById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBookById.fulfilled, (state, { payload }) => {
        state.bookById = payload.data;
      })
      .addCase(getBookById.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getGenreById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGenreById.fulfilled, (state, { payload }) => {
        state.genreById = payload.data;
      })
      .addCase(getGenreById.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getApplePrices.pending, () => {})
      .addCase(getApplePrices.fulfilled, (state, { payload }) => {
        state.applePrices = payload?.data ?? [];
      })
      .addCase(getApplePrices.rejected, () => {});
    builder
      .addCase(getGooglePrices.pending, () => {})
      .addCase(getGooglePrices.fulfilled, (state, { payload }) => {
        state.googlePrices = payload?.data ?? [];
      })
      .addCase(getGooglePrices.rejected, () => {});
    builder
      .addCase(createBook.pending, (state) => {
        state.createEditLoading = true;
      })
      .addCase(createBook.fulfilled, (state) => {
        state.createEditLoading = false;
      })
      .addCase(createBook.rejected, (state) => {
        state.createEditLoading = false;
      });
    builder
      .addCase(updateBook.pending, (state) => {
        state.createEditLoading = true;
      })
      .addCase(updateBook.fulfilled, (state) => {
        state.createEditLoading = false;
      })
      .addCase(updateBook.rejected, (state) => {
        state.createEditLoading = false;
      });
    builder
      .addCase(updatePrices.pending, (state) => {
        state.updatePriceLoading = true;
      })
      .addCase(updatePrices.fulfilled, (state) => {
        state.updatePriceLoading = false;
      })
      .addCase(updatePrices.rejected, (state) => {
        state.updatePriceLoading = false;
      });
  },
});

export const { setAppliedFilters, setBookById } = bookSlice.actions;

export default bookSlice.reducer;
