import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import {
  Select,
  MenuItem,
  InputLabel,
  Popover,
  OutlinedInput,
} from "@mui/material";
import {
  ApplyButton,
  FilterButton,
  FormWrapper,
  StyledFormControl,
} from "assets/filter/styled";
import { CONSTANTS, STRING } from "constants/general";
import {
  FILTERS_VALUE_CHANGE,
  FILTER_CHANGE,
  FILTER_CONSTANTS,
  TYPES_DATA,
} from "./constants";
import { FilterIcon } from "components/icons";

export const BooksFilters = ({ applyFilter, appliedFilters }) => {
  const genres = useSelector((state) => state.book.genres);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const list = [
    {
      column: FILTER_CONSTANTS.GENRE,
      queryKey: FILTER_CONSTANTS.GENRE_KEY,
      values: [],
      options: genres,
    },
    {
      column: FILTER_CONSTANTS.TYPE,
      queryKey: FILTER_CONSTANTS.TYPE_KEY,
      valueName: "type",
      options: TYPES_DATA,
    },
  ];

  const filterList = list;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilterChange = (event, filterIndex, queryKey, rejectPrefix) => {
    const target = { ...event, name: FILTER_CHANGE[queryKey] };

    const newFilters = [...selectedFilters];
    newFilters[filterIndex] = {
      ...newFilters[filterIndex],
      ...target,
      queryKey,
      rejectPrefix,
    };

    const data = {
      [FILTER_CONSTANTS.GENRE_KEY]: genres,
      [FILTER_CONSTANTS.TYPE_KEY]: TYPES_DATA,
    };

    newFilters[filterIndex].valueName = newFilters[filterIndex]?.value?.map(
      (_, index) =>
        FILTERS_VALUE_CHANGE[queryKey]({
          newFilters,
          filterIndex,
          data: data[queryKey],
          event,
          index,
        })
    );
    setSelectedFilters(newFilters);
  };

  const handleApplyFilter = () => {
    applyFilter(selectedFilters);
    handleClose();
  };

  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        display: "flex",
        justifyContent: "flex-end",
        margin: 10,
      }}
    >
      <FilterButton variant="outlined" onClick={handleClick}>
        <FilterIcon />
      </FilterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="add_gap"
      >
        <FormWrapper>
          {filterList.map((filter, idx) => (
            <Fragment key={filter.queryKey + idx}>
              <StyledFormControl size="small">
                <InputLabel
                  htmlFor={`outlined-age-native-simple-${idx}`}
                  className="custom-label"
                >
                  {filter.column}
                </InputLabel>
                <Select
                  name={filter.column}
                  multiple
                  value={
                    selectedFilters?.[idx]?.value
                      ? typeof selectedFilters?.[idx]?.value === STRING
                        ? selectedFilters?.[idx]?.value.splite(",")
                        : selectedFilters?.[idx]?.value
                      : []
                  }
                  onChange={(e) =>
                    handleFilterChange(
                      e.target,
                      idx,
                      filter?.queryKey,
                      filter?.rejectPrefix
                    )
                  }
                  className="custom-select"
                  input={
                    <OutlinedInput
                      name={filter.column}
                      label={filter.column}
                      id={`outlined-age-native-simple-${idx}`}
                      className="custom-input"
                    />
                  }
                >
                  {filter.options.map((filterItem) => (
                    <MenuItem
                      key={filterItem.id || filterItem._id}
                      value={filterItem.id || filterItem._id}
                    >
                      {filterItem.title}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Fragment>
          ))}
          <ApplyButton onClick={handleApplyFilter}>
            {CONSTANTS.APPLY}
          </ApplyButton>
        </FormWrapper>
      </Popover>
    </div>
  );
};
