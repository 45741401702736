export const BOOK = {
  cover_image: "Cover Image",
  title: "Title",
  author: "Author",
  genres: "Genres",
  page_count: "Pages Count",
  description: "Description",
  label: "Label",
  download_count: "Download",
  created_at: "Created Date",
  pdf: "PDF file",
  audio: "Audio file",
  download_pdf: "Click to download pdf",
  no_such_file: "No such file",
  fix_month: 1,
  check_to_add_zero: 10,
  upload_pdf: "Upload PDF",
  upload_audio: "Upload Audio",
  audio_type: "audio",
  pdf_type: "pdf",
  drop_files: "Drop files anywhere to upload",
  select_file: "Select File",
  or: "or",
  file_uploaded: "File uploaded successfully",
  upload_message: "(If you want upload another file please drop file)",
  drop_file_here: "Drop the files here ...",
  update_price: "Update Prices",
};
