import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHoveredComponent } from "./styled";

const CardDrag = ({
  id,
  index,
  children,
  canDrag,
  customClass,
  deactivateDragAndDrop,
  isDragActive,
  isHoveredItem,
}) => {
  const { isDragging, attributes, listeners, setNodeRef, transform } =
    useSortable({ id, disabled: !isDragActive });

  let style = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    cursor: "move",
    display: "flex",
    flex: 1,
    touchAction: "none",
    userSelect: "none",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={customClass}
      {...listeners}
      {...attributes}
    >
      {isHoveredItem ? <DragHoveredComponent /> : children}
    </div>
  );
};

export default CardDrag;
