import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { changePasswordSchema } from "./ChangePasswordSchema";
import { formErrorHelper, formikValidation } from "helpers/formikValidation";
import { CONSTANTS } from "constants/general";
import { TextField } from "@mui/material";
import {
  FORM_ITEM_NAMES_OBJ,
  FORM_ITEM_TYPES_OBJ,
  TEXTFIELD_VARIANTS_OBJ,
} from "service/constants";
import { useDispatch } from "react-redux";
import { SaveButton } from "components/Button";
import { changePassword } from "actions/usersActions";

export const ChangePassword = () => {
  const [wrongPassword, setWrongPassword] = useState("");

  const dispatch = useDispatch();

  const [initialValues, validation] = formikValidation(changePasswordSchema);

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      setWrongPassword("");
      dispatch(changePassword({ data: values, setWrongPassword }));
    },
  });

  return (
    <div className="form_container">
      <form onSubmit={formik.handleSubmit} className="create_edit_form">
        <div className="field_Container">
          <TextField
            autoFocus
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.oldPassword}
            name={FORM_ITEM_NAMES_OBJ.oldPassword}
            label={CONSTANTS.CURRENT_PASSWORD}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.password}
            value={formik.values[FORM_ITEM_NAMES_OBJ.oldPassword]}
            onChange={formik.handleChange}
            error={
              formErrorHelper({
                formik,
                elementName: FORM_ITEM_NAMES_OBJ.oldPassword,
                isBoolean: true,
              }) || !!wrongPassword
            }
            helperText={
              formErrorHelper({
                formik,
                elementName: FORM_ITEM_NAMES_OBJ.oldPassword,
              }) || wrongPassword
            }
          />
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.newPassword}
            name={FORM_ITEM_NAMES_OBJ.newPassword}
            label={CONSTANTS.NEW_PASSWORD}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.password}
            value={formik.values[FORM_ITEM_NAMES_OBJ.newPassword]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.newPassword,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.newPassword,
            })}
          />
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.newPasswordConfirmation}
            name={FORM_ITEM_NAMES_OBJ.newPasswordConfirmation}
            label={CONSTANTS.CONFIRM_PASSWORD}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.password}
            value={formik.values[FORM_ITEM_NAMES_OBJ.newPasswordConfirmation]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.newPasswordConfirmation,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.newPasswordConfirmation,
            })}
          />
        </div>
        <SaveButton />
      </form>
    </div>
  );
};
