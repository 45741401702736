export const CONSTANTS = {
  IMAGES_FIELD: "images",
  REQUIRED: "Required",
  DELETE: "delete",
  SUCCESS: "success",
  VALID_TEXT: "text",
  CHANGE_PASSWORD: "Change password",
  LOG_OUT: "Log out",
  OLD_PASSWORD: "Old password",
  PASSWORD: "Password",
  CURRENT_PASSWORD: "Current password",
  NEW_PASSWORD: "New password",
  CONFIRM_PASSWORD: "Confirm password",
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
  SEARCH: "search",
  SAVE: "Save",
  PASSWORD_CHANGE_SUCCESS: "Password changed successfully",
  EMAIL_VALIDATION_MESSAGE: "Please enter valid email",
  INVALID_PASSWORD_MESSAGE: "Invalid password",
  BACK_BUTTON: "Back",
  CREATE: "Create",
  EDIT: "Edit",
  REMOVE: "Remove",
  CLEAR: "clear",
  DELETE_BOOK: "Are you sure you want to delete?",
  FILTER: "Filter",
  FILTERS: "Filters",
  IMAGE: "Image",
  PDF: "PDF",
  TITLE: "Title",
  AUTHOR: "Author",
  GENRES: "Genres",
  PAGES_COUNT: "Pages Count",
  DESCRIPTION: "Description",
  LABEL: "Label",
  AUDIO: "Audio",
  COVER_IMAGE: "Cover Image",
  GENRE_DELETE:
    "Are you sure you want to delete? All the books in this genre will be deleted too.",
  NO_USER: "No Users",
  BLOCK_USER: "Are you sure you want to block this user?",
  UNBLOCK_USER: "Are you sure you want to unblock this user?",
  UNBLOCK: "Unblock",
  BLOCK: "Block",
  BLOCK_TITLE: "Block the user",
  UNBLOCK_TITLE: "Unblock the user",
  TYPES: "Types",
  APPLY: "Apply",
  TYPE_KEY: "type",
  GENRE_KEY: "genre",
  FIELD_REQUIRED: "This field is required",
  WRONG_PASSWORD: "Wrong password",
  already_exists: "Already exist",
  GENRE_ALREADY_EXIST: "The genre already exists",
  GOOGLE_PRICE: "Google Price",
  APPLE_PRICE: "Apple Price",
  PRICE_SELECT_TYPE: "select_price",
  APPLE_PRODUCT_ID: "product_id",
  ID_KEY: "id",
  LABEL_KEY: "label",
  FREE_VALUE: "free",
  PAID_VALUE: "paid",
  APPLY_KEY: "app_store_iap_id",
  GOOGLE_KEY: "google_play_iap_id",
  REQUIRED_MESSAGE: "One of the prices is required",
  PAGE_COUNT_KEY: "page_count",
};

export const DRAG_ITEM_INDEX = 1;
export const DRAG_HOVER_INDEX = 0;

export const LOGIN_PAGE = {
  welcome: "Welcome to E-Library Admin!",
  sign_in_text: "Sign in to your account to get started",
  sign_in: "Sign in",
  email_address: "Email address",
  password: "Password",
  remember_me: "Remember me",
};

export const ACCESS_TOKEN = "access_token";

export const TABLE = {
  subject: "Subject",
  private_subject: "Private Subject",
  zero_value: 0,
  changed_page_count: 1,
  rtl: "rtl",
};

export const PASSWORD_MESSAGES = {
  minMaxCharacter: "Password must be between 6 and 20 characters in length.",
  password_match: "Passwords must match",
  old_new_password_validation: "Old and new passwords cannot be the same.",
};

export const SEARCH_KEY = "search";
export const PAGE = "page";

export const FILTER_INPUT_SIZE_MEDIUM = "medium";
export const DEACTIVATE = "Off";
export const ACTIVATE = "On";
export const STRING = "string";

export const TABLE_PER_PAGE_COUNT = 20;
export const USER_PER_PAGE = 15;
export const TABLE_MIN_PAGE = 1;

export const SORT_MODE = {
  ASC: "asc",
  DESC: "desc",
};

export const SORT_PARAMS = {
  asc: "-1",
  desc: "1",
};
export const defaultSortParams = {
  sort: "createdAt",
  order: "-1",
};

export const defaultReversedSortParams = {
  sort: "createdAt",
  order: "-1",
};

export const DEFAULT_DATE_SORT_PARAMS = [
  {
    field: "createdAt",
    sort: SORT_MODE.DESC,
  },
];

export const DEFAULT_DATE_REVERSED_SORT_PARAMS = [
  {
    field: "createdAt",
    sort: SORT_MODE.ASC,
  },
];

export const MUI_BREAKPOINTS = {
  minDesktop: 1280,
  ipad: 1024,
  maxPhone: 768,
  mobile: 480,
};

export const INPUT_SIZES = {
  small: "small",
  medium: "medium",
};

export const PRODUCT_LABELS_DATA = [
  {
    id: "free",
    name: "Free",
  },
  {
    id: "paid",
    name: "Paid",
  },
];

export const TYPES_DATA = [
  {
    id: "pdf",
    name: "PDF",
  },
  {
    id: "audio",
    name: "Audio",
  },
];

export const PRICES_KEYS = {
  app_store_iap_id: "app_store_price",
  google_play_iap_id: "google_play_price",
};
