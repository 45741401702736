import { useEffect, useState } from "react";

import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox } from "@mui/material";

import { CONSTANTS, STRING } from "constants/general";
import {
  CustomFormHelperText,
  CustomInputLabel,
  StyledMenuItem,
} from "./styled";

export const MultipleSelect = ({
  data,
  title,
  handleSelect,
  name,
  formik,
  value,
}) => {
  const [selectedData, setSelectedData] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const chosenData = typeof value === STRING ? value.split(",") : value;
    setSelectedData(chosenData);
    handleSelect(name, chosenData);
  };

  useEffect(() => {
    setSelectedData([...value]);
  }, [value?.length]);

  return (
    <FormControl fullWidth>
      <CustomInputLabel
        id="demo-multiple-name-label"
        iserror={formik.touched[name] ? formik.errors[name] : undefined}
      >
        {title}
      </CustomInputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={selectedData}
        onChange={handleChange}
        input={<OutlinedInput label={title} />}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        renderValue={(selected) => {
          const selectedData = selected?.map(
            (el) => data?.find((item) => item?._id === el)?.title
          );
          return selectedData?.join(", ");
        }}
      >
        {data?.map((item) => (
          <StyledMenuItem key={item?._id} value={item?._id}>
            <Checkbox checked={selectedData.indexOf(item?._id) > -1} />
            {item?.title}
          </StyledMenuItem>
        ))}
      </Select>
      {formik.touched[name] && formik.errors[name] && (
        <CustomFormHelperText>{CONSTANTS.REQUIRED}</CustomFormHelperText>
      )}
    </FormControl>
  );
};
