import bookManagement from "assets/images/sidebar-menu/book-management.svg";
import book from "assets/images/sidebar-menu/book.svg";
import genres from "assets/images/sidebar-menu/genres.svg";
import userManagement from "assets/images/sidebar-menu/userManagement.svg";
import { PATHNAME } from "constants/pathnames";

export const Menu = [
  {
    id: "1",
    name: "Book Management",
    icon: <img src={bookManagement} alt="Book management" />,
    menu: [
      {
        id: 1,
        name: "Books",
        path: PATHNAME.books,
        icon: <img src={book} alt="Book" />,
      },
      {
        id: 2,
        name: "Genres",
        path: PATHNAME.genres,
        icon: <img src={genres} alt="Genres" />,
      },
    ],
  },
  {
    id: "2",
    name: "User Management",
    path: PATHNAME.users,
    icon: <img src={userManagement} alt="User management" />,
  },
];
