export const FILTERS_VALUE_CHANGE = {
  genre: ({ newFilters, filterIndex, data, index }) => {
    const value =
      data.find((genre) => genre._id === newFilters[filterIndex]?.value[index])
        ?.title || "";
    return value;
  },
  type: ({ newFilters, filterIndex, data, index }) => {
    const value =
      data.find((type) => type.id === newFilters[filterIndex]?.value[index])
        ?.title || "";
    return value;
  },
};

export const FILTER_CHANGE = {
  genre: "Genre",
  type: "Type",
};

export const FILTER_CONSTANTS = {
  GENRE_KEY: "genre",
  TYPE_KEY: "type",
  GENRE: "Genre",
  TYPE: "Type",
};

export const TYPES_DATA = [
  { title: "PDF", id: "pdf" },
  { title: "Audio", id: "audio" },
];
