import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import clsx from "clsx";

import { TextField } from "@mui/material";

import { setHeaderSearchHover } from "features/headerSlice";
import { PAGE, SEARCH_KEY } from "constants/general";

import searchIcon from "assets/images/search.svg";

const HeaderSearch = () => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const debounceTimer = useRef(null);

  const query = new URLSearchParams(useLocation().search);
  const searchParam = query.get(SEARCH_KEY);

  const { headerSearchHover } = useSelector((state) => state.header);

  const updateSearch = (newSearchValue) => {
    query.set(SEARCH_KEY, newSearchValue);
    query.set(PAGE, 1);
    navigate(`?${query.toString()}`);
  };

  const handleSearchChange = (e) => {
    const newSearchValue = e.target.value;
    setSearch(newSearchValue);

    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      updateSearch(newSearchValue);
    }, 500);
  };

  const toggleHeaderSearchHover = () => {
    dispatch(setHeaderSearchHover(!headerSearchHover));
  };

  useEffect(() => {
    if (searchParam) {
      const decodedSearchParam = decodeURIComponent(searchParam);
      setSearch(decodedSearchParam);
    }
  }, [searchParam]);

  useEffect(() => {
    return () => {
      clearTimeout(debounceTimer.current);
    };
  }, []);

  return (
    <form className="header-search-wrapper">
      <div
        className={clsx("search-wrapper", {
          "is-active": headerSearchHover || !!search,
        })}
      >
        <label className="icon-wrapper" htmlFor="search">
          <img src={searchIcon} alt="Search" className="search-icon" />
        </label>
        <TextField
          onFocus={toggleHeaderSearchHover}
          onBlur={toggleHeaderSearchHover}
          value={search}
          onChange={handleSearchChange}
          id="search"
          name="search"
          type="search"
          placeholder="Search terms..."
          variant="outlined"
        />
      </div>
    </form>
  );
};

export default HeaderSearch;
