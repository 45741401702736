import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "constants/general";

import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { authApi } from "service/authApi";
import { usersApi } from "service/usersApi";

export const getUsers = createAsyncThunk(
  "getUsers",
  async ({ params }, { rejectWithValue }) => {
    try {
      const { data } = await usersApi.getUsers(params);
      return data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async ({ params, id }, { rejectWithValue, dispatch }) => {
    try {
      await usersApi.deleteUser(id);
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
      dispatch(getUsers({ params }));
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const blockUser = createAsyncThunk(
  "blockUser",
  async ({ params, id }, { rejectWithValue, dispatch }) => {
    try {
      await usersApi.blockUser(id);
      Notify(NOTIFY.type.success, NOTIFY.message.update);
      dispatch(getUsers({ params }));
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async ({ data, setWrongPassword }, { rejectWithValue }) => {
    try {
      await authApi.changePassword(data);
      Notify(NOTIFY.type.success, CONSTANTS.PASSWORD_CHANGE_SUCCESS);
    } catch (error) {
      if (error?.response?.data?.message) {
        setWrongPassword(error?.response?.data?.message);
      }
      rejectWithValue(error.message);
    }
  }
);
