export const PATHNAME = {
  users: "/users",
  books: "/books",
  genres: "/genres",
};

export const PATHNAMES_INDEX = {
  books: 0,
};

export const PATHS_WITH_SEARCH = [PATHNAME.books];
