import { Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { booksSchema } from "./booksSchema";
import {
  UploadTabs,
  ImageInput,
  MultipleSelect,
  SelectBox,
  TextInput,
} from "components";
import { FORM_ITEM_TYPES } from "constants/pageName";
import { formikValidation } from "helpers/formikValidation";
import {
  createBook,
  getApplePrices,
  getBookById,
  getGenres,
  getGooglePrices,
  updateBook,
} from "actions/bookActions";
import { CONSTANTS, PRICES_KEYS, PRODUCT_LABELS_DATA } from "constants/general";
import { FORM_ITEM_NAMES_OBJ } from "service/constants";
import { SaveButton } from "components/Button";
import { formDataCollector } from "helpers/formikDataCollect";

export const CreateEditBook = () => {
  const genres = useSelector((state) => state.book.genres);
  const loading = useSelector((state) => state.book.createEditLoading);
  const editData = useSelector((state) => state.book.bookById);
  const applePrices = useSelector((state) => state.book.applePrices);
  const googlePrices = useSelector((state) => state.book.googlePrices);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [initialValues, validation] = formikValidation(booksSchema);

  const multipleSelectData = { genre_ids: genres };

  const selectData = {
    [CONSTANTS.LABEL]: PRODUCT_LABELS_DATA,
    [CONSTANTS.GOOGLE_PRICE]: googlePrices,
    [CONSTANTS.APPLE_PRICE]: applePrices,
  };

  const selectDataItemKey = {
    [CONSTANTS.GOOGLE_PRICE]: CONSTANTS.ID_KEY,
    [CONSTANTS.APPLE_PRICE]: CONSTANTS.APPLE_PRODUCT_ID,
  };

  const validationSchema = Yup.object().shape(
    {
      ...validation,
    },
    [
      ["audio", "pdf"],
      ["app_store_iap_id", "google_play_iap_id"],
    ]
  );

  const priceSchema = booksSchema.filter(
    (elem) => elem.type === CONSTANTS.PRICE_SELECT_TYPE
  );

  const shownPriceSchema = priceSchema.filter((elem) => !elem.not_show);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data = {};
      const updatedData = {};

      for (let key in values) {
        if (!!values[key] || values[key] !== "") {
          data[key] = values[key];
        }
      }

      for (let key in values) {
        updatedData[key] = values[key] || null;
      }

      const formData = formDataCollector(data);
      const updateFormData = formDataCollector(updatedData);

      if (id) {
        dispatch(updateBook({ data: updateFormData, id, navigate }));
      } else {
        dispatch(createBook({ data: formData, navigate }));
      }
    },
  });

  const handleFieldChange = (name, value) => {
    if (value !== " ") formik.setFieldValue(name, value);

    if (name === CONSTANTS.LABEL_KEY && value === CONSTANTS.FREE_VALUE) {
      priceSchema.forEach((elem) => {
        formik.setFieldValue(elem.backend_field, "");
      });
    }
  };

  const handleSetField = (name, value) => {
    if (value !== " ") {
      if (name === CONSTANTS.PAGE_COUNT_KEY) {
        const regex = /^\d+$/;
        const result = regex.test(value);

        return (result || !value) && formik.setFieldValue(name, value);
      }
      formik.setFieldValue(name, value);
    }
  };

  const handlePriceChange = (name, value, element) => {
    if (value !== " ") {
      formik.setFieldValue(name, value);
      formik.setFieldValue(PRICES_KEYS[name], element?.price);
    }
  };

  const selectChange = {
    [CONSTANTS.LABEL]: handleFieldChange,
    [CONSTANTS.GOOGLE_PRICE]: handlePriceChange,
    [CONSTANTS.APPLE_PRICE]: handlePriceChange,
  };

  useEffect(() => {
    dispatch(getGenres());
    dispatch(getApplePrices());
    dispatch(getGooglePrices());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getBookById({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (!!Object.keys(editData)?.length && id) {
      for (let key in formik.values) {
        formik.setFieldValue(key, editData[key]);
      }
    }
  }, [editData]);

  return (
    <div className="create_edit_container">
      <form onSubmit={formik.handleSubmit} className="form_container">
        <div className="create_section">
          {booksSchema.map((element) => (
            <Fragment key={element?.id}>
              {element.type === FORM_ITEM_TYPES.fileImage && (
                <div className="files_container">
                  <ImageInput
                    formik={formik}
                    element={element}
                    image={formik.values[element.backend_field]}
                    label={element.label}
                  />
                </div>
              )}
              {element.type === FORM_ITEM_TYPES.textarea && (
                <div className="field_container">
                  <TextInput
                    formik={formik}
                    handleChange={handleSetField}
                    type={element.type}
                    multiline={element.type === FORM_ITEM_TYPES.textarea}
                    name={element.name}
                    label={element.label}
                  />
                </div>
              )}
              {element.type === FORM_ITEM_TYPES.text && (
                <div className="field_container">
                  <TextInput
                    formik={formik}
                    type={element.type}
                    name={element.name}
                    label={element.label}
                    handleChange={handleSetField}
                  />
                </div>
              )}
              {element.type === FORM_ITEM_TYPES.multipleSelect && (
                <div className="field_container">
                  <MultipleSelect
                    title={element.label}
                    name={element.backend_field}
                    data={multipleSelectData[element.name]}
                    handleSelect={handleFieldChange}
                    formik={formik}
                    value={formik.values[element.backend_field]}
                  />
                </div>
              )}
              {element.type === FORM_ITEM_TYPES.select && (
                <>
                  <div className="field_container">
                    <SelectBox
                      title={element.label}
                      name={element.backend_field}
                      data={selectData[element.input_name]}
                      handleSelectChange={selectChange[element.input_name]}
                      formik={formik}
                      nameKey={FORM_ITEM_NAMES_OBJ.name}
                      selectedValue={formik.values[element.backend_field] ?? ""}
                    />
                  </div>
                  {formik.values[element.backend_field] === "paid" && (
                    <div className="field_container price_section">
                      {shownPriceSchema.map((item) => (
                        <SelectBox
                          title={item.label}
                          name={item.backend_field}
                          data={selectData[item.input_name]}
                          handleSelectChange={selectChange[item.input_name]}
                          formik={formik}
                          nameKey={FORM_ITEM_NAMES_OBJ.title}
                          itemKey={selectDataItemKey[item.input_name]}
                          selectedValue={
                            formik.values[item.backend_field] ?? ""
                          }
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </Fragment>
          ))}
          <div className="choose_files_container">
            <UploadTabs formik={formik} />
          </div>
          <SaveButton loading={loading} />
        </div>
      </form>
    </div>
  );
};
