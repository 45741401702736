import Button from "@mui/material/Button";
import Notify from "helpers/Notify";
import { NOTIFY } from "constants/notifyType";
import { getError } from "helpers/getError";
import { DeleteIcon, EditIcon } from "components/icons";
import { CONSTANTS } from "constants/general";
import { Tooltip } from "@mui/material";

export const getColumns = (deleteBook, editBook, isBookList) => [
  {
    field: "title",
    headerName: "Title",
    flex: 2,
    width: 100,
    sortable: isBookList,
  },
  {
    field: "author",
    headerName: "Author",
    flex: 2,
    width: 100,
    sortable: isBookList,
  },
  {
    field: "label",
    headerName: "Label",
    flex: 2,
    width: 100,
    sortable: isBookList,
  },
  {
    field: "createdAt",
    headerName: "Added Date",
    flex: 1,
    sortable: isBookList,
    width: 100,
    renderCell: (params) => {
      if (!params.value) return "-";
      const date = new Date(params.value);
      return date.toLocaleDateString();
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const handleDelete = async (event) => {
        event.stopPropagation();
        try {
          await deleteBook({ id: row._id });
        } catch (err) {
          Notify(NOTIFY.type.error, getError(err));
        }
      };

      const handleEdit = async (event) => {
        event.stopPropagation();
        try {
          await editBook({ id: row._id });
        } catch (err) {
          Notify(NOTIFY.type.error, getError(err));
        }
      };

      return (
        <div>
          <Tooltip title={CONSTANTS.EDIT} arrow>
            <Button
              className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
              variant="contained"
              color="secondary"
              onClick={handleEdit}
            >
              <EditIcon className="font-size-lg edit_icon" />
            </Button>
          </Tooltip>
          <Tooltip title={CONSTANTS.REMOVE} arrow>
            <Button
              className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              <DeleteIcon className="font-size-lg edit_icon" />
            </Button>
          </Tooltip>
        </div>
      );
    },
  },
];
