import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BooksFilters, ComponentHeader } from "components";
import ModalWindow from "components/ModalWindow";
import {
  TABLE_MIN_PAGE,
  TABLE_PER_PAGE_COUNT,
  SEARCH_KEY,
  CONSTANTS,
  DEFAULT_DATE_SORT_PARAMS,
  DEFAULT_DATE_REVERSED_SORT_PARAMS,
  defaultReversedSortParams,
  PAGE,
  SORT_PARAMS,
} from "constants/general";

import {
  deleteBook,
  getBooks,
  getGenres,
  updatePrices,
} from "actions/bookActions";

import { getColumns } from "./columns";
import { PrimaryTable } from "components/PrimaryTable";
import { EmptyRowsOverlay } from "./components/emptyRowsOverlay";
import FilterHelpers from "helpers/filter";
import { setAppliedFilters, setBookById } from "features/bookSlice";

let appliedFilterParams = {};

export const Books = () => {
  const [open, setOpen] = useState(false);
  const [goToPage, setGoToPage] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [sortTitle, setSortTitle] = useState(defaultReversedSortParams.sort);
  const [sortOption, setSortOption] = useState(defaultReversedSortParams.order);
  const [sortModelState, setSortModelState] = useState(
    DEFAULT_DATE_SORT_PARAMS
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const search = query.get(SEARCH_KEY);
  const page = query.get(PAGE);

  const books = useSelector((state) => state.book.data);
  const totalCount = useSelector((state) => state.book.totalCount);
  const appliedFiltersFromRedux = useSelector(
    (state) => state.book.appliedFilters
  );

  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleClearFilters,
    appliedFiltersToParams,
    appliedFilters,
  } = FilterHelpers(appliedFiltersFromRedux);

  const totalPageNumber = Math.ceil(totalCount / TABLE_PER_PAGE_COUNT);

  const openDeleteModal = (row) => {
    setDeleteId(row?.id);
    setOpen(true);
  };

  const handleEdit = (row) => {
    dispatch(setBookById({}));
    navigate(`edit/${row?.id}`);
  };

  const columns = getColumns(openDeleteModal, handleEdit, !!books?.length);

  const handlePageChange = (_, newPage) => {
    query.set(PAGE, newPage);
    navigate(`?${query.toString()}`);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const goToSpecificPage = () => {
    const newPage = Number(goToPage);
    if (newPage >= TABLE_MIN_PAGE && newPage <= totalPageNumber) {
      query.set(PAGE, newPage);
      navigate(`?${query.toString()}`);
    }
  };
  const handleRowClick = (param) => {
    navigate(`/book-details/${param.id}`);
  };

  const handleCreate = () => {
    dispatch(setBookById({}));
    navigate("create");
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length) {
      setSortModelState(sortModel);
      setSortTitle(sortModel[0].field);
      setSortOption(SORT_PARAMS[sortModel[0].sort]);
    } else {
      setSortModelState(DEFAULT_DATE_REVERSED_SORT_PARAMS);
      setSortTitle(defaultReversedSortParams.sort);
      setSortOption(defaultReversedSortParams.order);
    }
  };

  const handleUpdatePrice = () => {
    const params = {
      page: 1,
      sort: defaultReversedSortParams.sort,
      order: defaultReversedSortParams.order,
    };

    dispatch(
      updatePrices({
        params,
        query,
        handleClearFilters,
        setSortTitle,
        setSortOption,
        navigate,
      })
    );
  };

  useEffect(() => {
    dispatch(setAppliedFilters(appliedFilters));
  }, [appliedFilters, page]);

  useEffect(() => {
    appliedFilterParams = appliedFiltersToParams(appliedFiltersFromRedux);
    dispatch(
      getBooks({
        page,
        search,
        sort: sortTitle,
        order: sortOption,
        filters: {
          genre: appliedFilterParams?.genre,
          type: appliedFilterParams?.type,
        },
      })
    );
  }, [page, search, sortTitle, sortOption, appliedFiltersFromRedux]);

  useEffect(() => {
    dispatch(getGenres());
  }, []);

  return (
    <div className="table_page_body_container position_relative">
      <ComponentHeader
        create
        handleCreateEdit={handleCreate}
        updatePrices
        handleUpdatePrice={handleUpdatePrice}
      />
      {open && (
        <ModalWindow
          open={open}
          setOpen={setOpen}
          id={deleteId}
          confirmRequest={deleteBook}
          currentPage={page}
          message={CONSTANTS.DELETE_BOOK}
          confirmBtnText={CONSTANTS.DELETE}
          params={{
            page,
            search,
            sort: sortTitle,
            order: sortOption,
            filters: {
              genre: appliedFilterParams?.genre,
              type: appliedFilterParams?.type,
            },
          }}
        />
      )}
      <PrimaryTable
        filterComponent={
          <BooksFilters
            applyFilter={handleApplyFilter}
            appliedFilters={appliedFilters}
          />
        }
        rows={books.length ? books : []}
        handleClearFilters={handleClearFilters}
        handleRemoveFilter={handleRemoveFilter}
        columns={columns}
        handlePageChange={handlePageChange}
        handleGoToPageChange={handleGoToPageChange}
        totalPageNumber={totalPageNumber}
        handleCreate={handleCreate}
        page={page}
        goToPage={goToPage}
        goToSpecificPage={goToSpecificPage}
        handleRowClick={handleRowClick}
        handleSortModelChange={handleSortModelChange}
        sortModel={sortModelState}
        hasPagination={totalCount > TABLE_PER_PAGE_COUNT}
        EmptyRowsOverlay={EmptyRowsOverlay}
        appliedFilters={appliedFilters}
      />
    </div>
  );
};
