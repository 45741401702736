import { formErrorHelper } from "helpers/formikValidation";
import { useCallback, useState } from "react";
import { UploadFile } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import ClearIcon from "@mui/icons-material/Clear";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

export const ImageInput = ({
  formik,
  image,
  element,
  label,
  customClass = "",
}) => {
  const [showImage, setShowImage] = useState();

  const acceptedTypes = {
    "image/*": [".png", ".jpg", ".jpeg", ".webp", ".svg"],
  };

  const onDrop = useCallback((acceptedFiles) => {
    formik.setFieldValue(element.backend_field, acceptedFiles[0]);

    setShowImage(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedTypes,
  });

  const deleteImage = (e) => {
    e.stopPropagation();
    formik.setFieldValue(element.backend_field, "");
    setShowImage();
  };

  return (
    <div className="field_container file_content" {...getRootProps()}>
      <div className="image_wrapper">
        {label && <p className="image_label">{label}</p>}
        <button className="delete_btn" type="button" onClick={deleteImage}>
          <ClearIcon />
        </button>
        <label
          className={`create_edit_image
            ${image || showImage ? customClass : ""}
            ${
              formErrorHelper({
                formik,
                elementName: element.name,
                isBoolean: true,
              })
                ? "create_edit_image_error"
                : ""
            }`}
          htmlFor={element.name}
        >
          {showImage ? (
            <img src={URL.createObjectURL(showImage)} alt="Avatar" />
          ) : !!image ? (
            <img src={`${image_url}${image}`} alt="Avatar" />
          ) : (
            <UploadFile />
          )}
        </label>
        <input {...getInputProps()} className="create_edit_image_input" />
      </div>
    </div>
  );
};
