import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router";

import { Button } from "@mui/material";
import { BOOK } from "constants/books";
import { STRING } from "constants/general";
import { downloadAudioPdf } from "actions/bookActions";
import ClearIcon from "@mui/icons-material/Clear";

import pdfImage from "assets/images/pdf.png";

const imageBase = process.env.REACT_APP_API_IMAGE_URL;

export const PdfUploader = ({ type, value, formik, isError }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const onDrop = useCallback(
    (acceptedFiles) => {
      formik.setFieldValue(type, acceptedFiles[0]);
    },
    [type]
  );

  const acceptedTypes =
    type === BOOK.audio_type
      ? { "audio/mp3": [".mp3", ".wav", ".aiff"] }
      : { "application/pdf": [".pdf", ".epub"] };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedTypes,
  });

  const downloadPdf = (e, type, value) => {
    e.stopPropagation();

    const splitFile = value.split(".");
    const download_type = splitFile[1];

    dispatch(downloadAudioPdf({ type, id, download_type }));
  };

  const deleteFile = (e) => {
    e.stopPropagation();
    formik.setFieldValue(type, "");
  };

  return (
    <div
      className={`upload_container ${isError ? "error_container" : ""}`}
      {...getRootProps()}
    >
      {!!value && (
        <button className="delete_btn" type="button" onClick={deleteFile}>
          <ClearIcon />
        </button>
      )}
      <input {...getInputProps()} />
      {isDragActive ? (
        <>
          <p>{BOOK.drop_file_here}</p>
        </>
      ) : !value ? (
        <>
          <p className="drop_text">{BOOK.drop_files}</p>
          <p className="drop_text small_size">{BOOK.or}</p>
          <Button variant="contained" className="select_file_btn">
            {BOOK.select_file}
          </Button>
        </>
      ) : (
        <>
          <div className="message_container">
            {typeof value === STRING ? (
              <>
                {type === BOOK.audio_type ? (
                  <audio controls className="audio">
                    <source src={`${imageBase}${value}`}></source>
                  </audio>
                ) : (
                  <>
                    <img src={pdfImage} alt="PDF" className="pdf_imag" />
                    <button
                      className="pdf_download"
                      type="button"
                      onClick={(e) => downloadPdf(e, type, value)}
                    >
                      {BOOK.download_pdf}
                    </button>
                  </>
                )}
              </>
            ) : type === BOOK.audio_type ? (
              <audio controls className="audio">
                <source src={URL.createObjectURL(value)}></source>
              </audio>
            ) : (
              <img src={pdfImage} alt="PDF" className="pdf_imag" />
            )}
            <p className="upload_message">{BOOK.upload_message}</p>
          </div>
          <p className="drop_text small_size">{BOOK.or}</p>
          <Button variant="contained" className="select_file_btn">
            {BOOK.select_file}
          </Button>
        </>
      )}
    </div>
  );
};
