import Routes from "Routes";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ToastContainer } from "react-toastify";

//Styles
import "./App.css";
import "./assets/base.scss";

function App() {
  return (
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <Routes />
      </DndProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
