export const PAGE_NAME_OBJ = {
  books: "books",
  users: "users",
};

export const FORM_ITEM_TYPES = {
  array: "array",
  text: "text",
  textarea: "textarea",
  select: "select",
  multipleSelect: "multipleSelect",
  selectOfArray: "selectArray",
  projectOfArray: "projectArray",
  blogOfArray: "blogArray",
  secondSelect: "secondSelect",
  file: "file",
  oldArray: "oldArray",
  date: "date",
  textLocation: "textLocation",
  textContactInfo: "textContactInfo",
  CKEditor: "CKEditor",
  imageArray: "imageArray",
  otherServices: "other_services",
  careerArray: "careerArray",
  password: "password",
  checkbox: "checkbox",
  questionArray: "questionArray",
  fileImage: "fileImage",
};
