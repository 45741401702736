export const StatusLabel = ({
  children,
  name,
  className,
  onClick = () => {},
}) => {
  return (
    <div className={`${className} status_label`} onClick={onClick}>
      {children}
      <span>{name}</span>
    </div>
  );
};
