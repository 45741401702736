import { createSlice } from "@reduxjs/toolkit";
import { getUsers } from "actions/usersActions";

const initialState = {
  data: [],
  totalCount: 0,
  loading: false,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.data = payload?.data?.users;
        state.totalCount = payload?.data?.totalCount;
      })
      .addCase(getUsers.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default usersSlice.reducer;
